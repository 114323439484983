import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Agenda from '../components/agenda'

const AgendaEn = ({location}) => (
  <Layout lang="en" location={location}>
    <SEO title="Calendar" description="Diary of excursions in the Jura of Lacuzon Loisirs" lang="en" />
    <Agenda lang="en" />
  </Layout>
)

export default AgendaEn
