import React, { useState, useEffect } from "react"

const AgendaComponent = ({ lang }) => {
  const [time, setTime] = useState(0)
  useEffect(() => {
    setTime(new Date() * 1)
  }, [])
  return (
    <div className="has-text-centered mt-6 mb-6">
      <a href={`/programme-lacuzon-loisirs.pdf?t=${time}`}>
        <img
          src={`/programme-lacuzon-loisirs.jpg?t=${time}`}
          alt={lang === "fr" ? "Agenda des sorties" : "Hikes agenda"}
          style={{ maxWidth: 800 }}
        />
        <div>
          {lang === "fr"
            ? "Télécharger / imprimer le programme"
            : "Download / print programme"}
        </div>
      </a>
    </div>
  )
}

export default AgendaComponent
